<!-- System: STA
    Purpose: This compoment will display the download 
              page of applications to user.
-->
<template>
  <v-app>
    <div class="main-download">
      <v-container>
        <div
          v-if="Keys && selectedCompanyUserStatus !== 'employee'"
          class="row tabs tabs-invibleapp"
        >
          <v-spacer></v-spacer>
          <!-- Download Tabs -->
          <v-tabs
            class="slider-tabs"
            background-color="transparent"
            centered
            v-model="silentApp"
          >
            <v-tabs-slider color="blue"></v-tabs-slider>
            <v-tab @click="hideInstructions()" class="font-size"
              >Desktop App</v-tab
            >
            <v-tab @click="hideInstructions()" class="font-size"
              >Silent App</v-tab
            >
          </v-tabs>
          <v-spacer></v-spacer>
        </div>
      </v-container>
      <!-- Desktop app label -->
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="download-head">
              <h1>Desktop Apps</h1>
            </div>
          </v-col>
        </v-row>
        <!-- Download app description -->
        <v-row>
          <v-col cols="12">
            <div class="web-text" v-if="!isTablet">
              <p class="font-weight-medium" v-if="this.silentApp == false">
                Download the desktop app to <br />start your time and task
                tracking
              </p>
              <p class="font-weight-medium" v-else>
                Download the Silent app to <br />start your time and task
                tracking
              </p>
            </div>
          </v-col>
        </v-row>
        <!-- Not available for tablets info -->
        <div class="app-for-mobile" v-if="isTablet">
          <v-col cols="12">
            <h6>
              Currently not available
              <br />for small devices
            </h6>
          </v-col>
        </div>

        <div id="available-app" class="app-for-web app-desing" v-if="!isTablet">
          <v-row>
            <!-- For windows -->
            <v-col cols="12" sm="6" md="4" id="app-lwm">
              <v-card :elevation="1" class="app-card">
                <div class="img-p pt-4">
                  <img src="@/assets/images/dashboard_icons/window_new.svg" />
                </div>
                <h3 class="op-name">Windows</h3>
                <h4 class="avail-name pr-10">Available for Windows</h4>
                <v-row class="download-app-list">
                  <v-col>
                    <h5>Windows 10</h5>
                  </v-col>
                  <v-col>
                    <h5>Windows 8 & 8.1</h5>
                  </v-col>
                  <v-col>
                    <h5>Windows 7</h5>
                  </v-col>
                </v-row>
                <v-row class="button-setting" v-if="this.silentApp == false">
                  <v-col>
                    <v-btn
                      class="btn-download"
                      outlined
                      color="blue !important"
                      href="https://api.stafftimerapp.com/download/app/v3/win32-x64-prod"
                      download
                      >Download (x64)</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      class="btn-download"
                      outlined
                      color="blue !important"
                      href="https://api.stafftimerapp.com/download/app/v3/win32-ia32-prod"
                      download
                      >Download (x86)</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row class="button-setting" v-else>
                  <v-col cols="12">
                    <v-btn
                      outlined
                      color="blue"
                      @click="
                        (showWindowInstructions = true),
                          (showMacInstructions = false),
                          (showLinuxInstructions = false)
                      "
                      href="#silent-window-app"
                      class="btn-download"
                      >How to install</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <!-- For MacOS -->
            <v-col cols="12" sm="6" md="4" id="app-lwm">
              <v-card :elevation="1" class="app-card">
                <div class="text-center single-portion" id="mac_app">
                  <div class="img-p pt-4">
                    <img src="@/assets/images/dashboard_icons/mac_new.svg" />
                  </div>
                  <h3 class="op-name">Mac OS X</h3>
                  <h4 class="avail-name">Available for Mac OS</h4>
                  <v-row class="download-app-list">
                    <v-col class="col-mac">
                      <h5>macOS 10.9 , 10.10, 10.12, 10.13, 10.14</h5>
                    </v-col>
                  </v-row>
                  <div class="row button-setting">
                    <v-col
                      cols="12"
                      class="text-center dnlod-btn-col spacing-invisble"
                    >
                      <v-btn
                        outlined
                        color="blue"
                        v-if="silentApp == false"
                        :href="$api_url + 'download/app/v3/darwin-x64-prod'"
                        class="btn-download"
                        download
                        >Download</v-btn
                      >
                      <v-btn
                        outlined
                        color="blue"
                        v-else
                        @click="
                          (showMacInstructions = true),
                            (showWindowInstructions = false),
                            (showLinuxInstructions = false)
                        "
                        href="#silent-mac-app"
                        class="btn-download"
                        >How to install</v-btn
                      >
                    </v-col>
                  </div>
                </div>
              </v-card>
            </v-col>
            <!-- For LinuXOS -->

            <v-col cols="12" sm="6" md="4" id="app-lwm">
              <v-card :elevation="1" class="app-card">
                <div class="text-center single-portion">
                  <div class="linux-image">
                    <img
                      src="@/assets/images/dashboard_icons/linux_new.svg"
                      class="linuximge custom-img-style"
                    />
                  </div>
                  <h3 class="op-name">Linux OS</h3>
                  <h4 class="avail-name">Available for Linux OS</h4>
                  <v-row class="download-app-list">
                    <v-col class="col-mac">
                      <h5>Ubuntu 16.04.6 and Higher</h5>
                    </v-col>
                  </v-row>
                  <div class="row button-setting">
                    <v-col
                      cols="12"
                      class="text-center dnlod-btn-col spacing-invisble"
                    >
                      <v-btn
                        outlined
                        color="blue"
                        v-if="this.silentApp == false"
                        href="https://api.stafftimerapp.com/download/app/linux"
                        class="btn-download"
                        download
                        >Download</v-btn
                      >
                      <v-btn
                        outlined
                        color="blue"
                        v-else
                        @click="
                          (showMacInstructions = false),
                            (showWindowInstructions = false),
                            (showLinuxInstructions = true)
                        "
                        href="#silent-linux-app"
                        class="btn-download"
                        >How to install</v-btn
                      >
                    </v-col>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <div>
        <SilentAppMacOS
          id="silent-mac-app"
          v-if="showMacInstructions == true"
        />
        <SilentAppWindows
          id="silent-window-app"
          v-if="showWindowInstructions == true"
        />
        <SilentAppLinuxOS
          id="silent-linux-app"
          v-if="showLinuxInstructions == true"
        />
      </div>
    </div>
  </v-app>
</template>

<script>
import { sha256 } from "js-sha256";
import { mapState } from "vuex";
export default {
  name: "download-app",
  components: {
    SilentAppMacOS: () =>
      import("../components/downloadappbackoffice/SilentAppMacOS"),
    SilentAppLinuxOS: () =>
      import("../components/downloadappbackoffice/SilentAppLinuxOS"),
    SilentAppWindows: () =>
      import("../components/downloadappbackoffice/SilentAppWindows"),
  },
  data() {
    return {
      silentApp: false,
      ua: "",
      device: "",
      getAllKeys: [],
      showMacInstructions: false,
      showWindowInstructions: false,
      showLinuxInstructions: false,
    };
  },
  computed: {
    ...mapState("custom", ["companies_list"]),
    isTablet() {
      return /(ipad|tablet|android|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|iphone|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        navigator.userAgent.toLowerCase()
      );
      // return new MobileDetect(window.navigator.userAgent).mobile();
    },
    keyData() {
      if (this.$route.params.id) {
        return sha256(this.$route.params.id);
      }
    },
    Keys() {
      this.getAllKeys = this.$store.state.pf.keysData;
      if (this.getAllKeys.includes(this.keyData)) {
        return true;
      }
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
  },
  methods: {
    showSilentApp() {
      this.silentApp = !this.silentApp;
    },
    hideInstructions() {
      this.showMacInstructions = false;
      this.showWindowInstructions = false;
      this.showLinuxInstructions = false;
    },
  },
};
</script>

<style scoped>
.app-card {
  min-height: 350px;
}
.slider-tabs {
  margin: 29px 0;
  background: transparent;
}
.app-card {
  padding: 20px 0 30px;
}
.avail-name {
  padding: 17px 0;
}
.btn-download {
  color: #4d75f6;
  width: 154px;
  font-size: 12px !important;
  border: 1px solid #4d75f6;
}
</style>